'use client'
import { styled, Box } from "@mui/material";
import theme from "@/theme/theme";

export const CookieBannerWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}))
export const CookieBannerContent = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "img": {
        marginInlineEnd: theme.sizes.baseMargin
    },
}))
export const CookieBannerTexts = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    ".cookie-links-wrapper": {
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        marginTop: theme.sizes.baseMargin
    },
    [`${theme.mediaQueries.desktopM}`]: {
        ".cookie-links-wrapper": {
            marginTop: 0
        }, 
    }
}))


'use client'

import React, { useEffect, useState } from 'react'
import { BottomBannerContent, BottomBannerWrapper } from './styles'
import { BottomBannerProps } from './types'
import { Popover } from '@mui/material'
import { CloseButton } from '../ActionButton/ActionButton'
import theme from '@/theme/theme'
import { handleGTMEvent } from '@/common/GTMEventSender'

const ANIMATION_DURATION = 1000; // in milliseconds

const BottomBanner:React.FC<BottomBannerProps> = ({children, GTMEvent, additionalCloseFunc}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isClosing, setIsClosing] = useState(false); // To control the close animation
  
    // useEffect to automatically trigger the popover on page load
    useEffect(() => {
        const dummyElement = document.createElement('div');
        document.body.appendChild(dummyElement);
        setAnchorEl(dummyElement); // Set anchor element to the dummy element
        return () => {
          // Safely remove only if it's still a child of document.body
          if (document.body.contains(dummyElement)) {
            document.body.removeChild(dummyElement);
          }
        };
    }, []);
  
    // Handle close with animation
    const handleClose = () => {
      setIsClosing(true); // Trigger closing animation
      setTimeout(() => {
        setAnchorEl(null);
        setIsClosing(false); // Reset isClosing after animation duration
        handleGTMEvent(GTMEvent ? GTMEvent : "bottom-banner", `${GTMEvent}-close-button`,{
          item_text: "X",
          item_category: "close-button",
        })
      }, ANIMATION_DURATION); // Match this duration to your CSS transition
      if(typeof additionalCloseFunc ==='function') additionalCloseFunc();
    };
  
    const open = Boolean(anchorEl) && !isClosing;
    const id = open ? 'simple-popover' : undefined;
  
  return (
    <BottomBannerWrapper>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {}}
        disableEnforceFocus // Allows interaction with the rest of the page
        disableScrollLock // Prevents scroll locking when the popover is open
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        slotProps={{
            root: {
              onClick: (e) => e.stopPropagation(), // Allows the page to remain interactive
              hideBackdrop: true,
              sx: {
                pointerEvents: "none"
              }
            },
            paper: {
                sx: {
                    position:'fixed',
                    width: '100%', // Full-width to mimic a bottom-drawer
                    maxWidth: 'none', // Override default MUI maxWidth
                    minHeight: '88px',
                    overflow: 'hidden', // Hide overflow during the animation
                    transition: `transform ${ANIMATION_DURATION}ms ease`, // Smooth transition for transform
                    transform: isClosing ? 'translateY(100%)' : 'translateY(0)', // Move down when closing
                    bottom: 0,
                    top: "unset !important",
                    left: 0,
                    borderTop: `2px solid ${theme.colors.mainBlue}`,
                    borderRadius: 0,
                    pointerEvents: "auto"
                  },
            }
          }}
      >

        <BottomBannerContent>
            {children}
            <CloseButton variant={'ghost'} id={`${GTMEvent}-close-button`} className='circle'  onClick={handleClose}/>
        </BottomBannerContent>
      </Popover>
    </BottomBannerWrapper>
  )
}

export default BottomBanner
'use client'

import React from 'react';
import { LinkMenuProps } from './types';
import { Typography } from '@mui/material';
import Logo from '@/public/logos/logo-inline-small.svg';
import { LinkMenuWrapper, StyledLinkItem, StyledLinkReplacement } from './styles';
import { Icons } from '../../reusable/IconsComponents';
import { Desktop } from '@/common/MediaQueries';
import { handleGTMEvent } from '@/common/GTMEventSender';
import { toPascalCase } from '@/lib/utils/stringMethods';
import { gsap } from 'gsap';

const LinkMenu: React.FC<LinkMenuProps> = ({ data, className, GTMEventName }) => {
    let item;
    switch(true) {
        case data.item !== undefined:
            item = data.item;
        break;
        case data.children !== undefined:
            item = data.children;
        break;
        default:
            item = data;
    }

    const handleAction = (title:string, isSearch:boolean | undefined) => {
        const searchElement = document.getElementById('search-component');
        if(isSearch && searchElement) {
            gsap.to(window, {
                scrollTo: { y: searchElement, offsetY: 20 },
                duration: 2.5, // Animation duration in seconds
                ease: 'power2.out', // Easing function
              });
        }
        handleGTMEvent(GTMEventName ? `${GTMEventName}` : 'link-button', `${GTMEventName}-link-button-${title}`,{
        item_url: "null",
        item_text: title,
        item_category: "top_header",
        })
    }

    return (
      <LinkMenuWrapper className={className}>
        {data?.title &&
        <Typography key={ 'link-menu-title' + data?.title } className="title" variant="subtitle1">
            {data?.title}
        </Typography>
        }
        { item && Array.isArray(item) && item.map((linkItem, index) => {
            const { href, title } = linkItem;
            const classArr = linkItem.class;
            const iconName = classArr && classArr[0] !== 'desktop' ? toPascalCase(classArr[0]) + "Icon" : null;
            const ImportedIcon = iconName ? Icons[iconName as keyof typeof Icons] : null; // import icon dynamically
            const isSearch = classArr && classArr.includes('search');
            if(href && href !== '/nouse') {
                return (
                    <StyledLinkItem key={'link-menu-item' + title ? title : '' + index}
                                    className={`nav-item ${ classArr && classArr.includes('logo') ? 'logo-wrapper' : ''} ${ ImportedIcon ? 'icon-wrapper' : 'icon'}`}
                                    href={href}
                                    id={`${GTMEventName}-link-menu-item-${title}`}
                                    sx={{display: classArr && classArr.includes('desktop') ? { xs: "none", md: "flex" } : ''}} /* if had class 'desktop' - show only of desktop */
                                    onClick={() => 
                                        handleGTMEvent(GTMEventName ? `${GTMEventName}` : 'link-menu-item', `${GTMEventName}-link-menu-item-${title}`,{
                                        item_url: href ? href : "null",
                                        item_text: title,
                                        item_category: "top_header",
                                    })}
                                    > 
                        { classArr&&classArr.includes('logo') ? // when logo - display logo instead of text
                            <Logo className={"logo"}/>
                        : // default option of text and link
                        <>
                        { ImportedIcon ? 
                        <>
                            <Desktop> {/* show only icon on mobile only if there's an icon */}
                                <Typography key={ 'link-menu-item' + title ? title : '' + index } variant="subtitle1">
                                    {title}
                                </Typography>
                            </Desktop>
                            { ImportedIcon && <ImportedIcon /> } 
                        </>
                        :
                            <Typography key={ 'link-menu-item' + title ? title : '' + index } variant="subtitle1">
                                {title}
                            </Typography>
                        }
                        </>
                        }
                    </StyledLinkItem>
                )
            } else {
                return ( // handing when an item is not a link but has an action instead
                    <StyledLinkReplacement key={'link-menu-item' + title ? title : '' + index}
                                           onClick={()=> handleAction(title, isSearch) }
                                           variant="subtitle1"
                                           id={`${GTMEventName}-link-button-${title}`}
                                           className={`${classArr && classArr.includes('title') ? 'title' : ''} ${ ImportedIcon ? 'icon-wrapper' : 'icon'}`}
                                           sx={{display: classArr && classArr.includes('desktop') ? { xs: "none", md: "flex" } : ''}} /* if had class 'desktop' - show only of desktop */
                                           >
                        <Desktop>{title}</Desktop> {/* show only icon on mobile */}
                        { ImportedIcon && <ImportedIcon /> } 
                    </StyledLinkReplacement>
                )
            }
        })}
      </LinkMenuWrapper>
    );
  };
  
  export default LinkMenu;
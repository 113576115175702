'use client'
import { styled, Box, Container } from "@mui/material";
import theme from "@/theme/theme";

export const BottomBannerWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}))

export const BottomBannerContent = styled(Container)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.sizes.basePadding,
    height: "100%",
    "> *:last-child": {
        marginInlineStart: "auto"
    },
    [`${theme.mediaQueries.desktopM}`]: {
        padding: theme.sizes.basePadding,
    }
}))
"use client";

import React, { useCallback, useEffect, useState } from "react";
import mockMegaMenu from "../MegaMenu/mockMegaMenu.json";
import buildMenuHierarchy from "@/lib/utils/menuParsers";
import {
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { Menu as MenuIcon, ExpandLess, ExpandMore } from "@mui/icons-material";
import MegaMenuItem from "../MegaMenu/MegaMenuItem";
import { MobileAllMenuWrapper, StyledDrawer } from "./styles";
import { handleGTMEvent } from "@/common/GTMEventSender";
import { MobileAllMenuProps } from "./types";

const MobileAllMenu: React.FC<MobileAllMenuProps> = ({
  data,
  GTMEventName,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  if (!data) data = mockMegaMenu;
  const megaMobileData = buildMenuHierarchy(data);

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  const handleSubMenuClick = useCallback((text: string) => {
    setOpenSubMenu((prev) => (prev === text ? null : text));
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        drawerOpen &&
        event.target instanceof Node &&
        !document
          .querySelector(".mobile-menu-button")
          ?.contains(event.target) &&
        !document.querySelector(".MuiDrawer-root")?.contains(event.target)
      ) {
        setDrawerOpen(false);
      }
    },
    [drawerOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <MobileAllMenuWrapper>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        className="mobile-menu-button"
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
      <StyledDrawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        hideBackdrop={true}
      >
        <List>
          {megaMobileData.map((item, outerIndex) => {
            if (item.children && item.children.length > 0) {
              return (
                <React.Fragment key={`${item.title}-${outerIndex}`}>
                  <ListItem
                    onClick={() =>
                      item.children && handleSubMenuClick(item.title)
                    }
                  >
                    <a
                      id={`${GTMEventName}-mobile-mega-link-${item.title}`}
                      onClick={() =>
                        handleGTMEvent(
                          GTMEventName
                            ? `${GTMEventName}-mobile`
                            : "mobile-mega-link",
                          `${GTMEventName}-mobile-mega-link-${item.title}`,
                          {
                            item_url: item.href ? item.href : "null",
                            item_text: item.title,
                          }
                        )
                      }
                    >
                      <ListItemText primary={item.title} />
                    </a>
                    {item.children &&
                      item.children.length > 0 &&
                      (openSubMenu === item.title ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </ListItem>
                  <Collapse
                    in={openSubMenu === item.title}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.children && item.children.length > 0 ? (
                        item.children.map((child, index) => {
                          const currIndex = index;
                          const itemsAmount = item.children
                            ? item.children.length - 1
                            : undefined;
                          return (
                            <MegaMenuItem
                              GTMEventName={GTMEventName}
                              key={`${child.title}-${index}`}
                              outerChildren={itemsAmount}
                              item={child}
                              outerIndex={currIndex}
                            />
                          );
                        })
                      ) : (
                        <a
                          key={`mega-link-${item.title}-${outerIndex}`}
                          id={`${GTMEventName}-mega-link-${item.title}-${outerIndex}`}
                          href={item.href ? item.href : ""}
                          onClick={() =>
                            handleGTMEvent(
                              GTMEventName
                                ? `${GTMEventName}-mobile`
                                : "mobile-mega-link",
                              `${GTMEventName}-mega-link-${item.title}-${outerIndex}`,
                              {
                                item_url: item.href ? item.href : "null",
                                item_text: item.title,
                              }
                            )
                          }
                        >
                          {item.title}
                        </a>
                      )}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            } else
              return (
                <ListItem key={`${item.title}-${outerIndex}`}>
                  {item.class && item.class.includes("button") ? (
                    <Button
                      href={item.href ? item.href : ""}
                      id={`${GTMEventName}-mega-link-${item.title}`}
                      onClick={() =>
                        handleGTMEvent(
                          GTMEventName
                            ? `${GTMEventName}-mobile`
                            : "mobile-mega-link",
                          `${GTMEventName}-mega-link-${item.title}`,
                          {
                            item_url: item.href ? item.href : "null",
                            item_text: item.title,
                          }
                        )
                      }
                    >
                      {item.title}
                    </Button>
                  ) : (
                    <a href={item.href ? item.href : ""}>{item.title}</a>
                  )}
                </ListItem>
              );
          })}
        </List>
      </StyledDrawer>
    </MobileAllMenuWrapper>
  );
};

export default MobileAllMenu;

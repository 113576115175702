'use client'

import { Box, List, ListItem, Popover } from '@mui/material';
import { styled } from "@mui/material/styles";
import theme, { imageUrl } from "@/theme/theme";

/* MegaMenu */
export const MegaMenuDesktop = styled(List)(() => ({
    position:"relative",
    display: 'flex',
    padding: 0,
    width: "100%",
    justifyContent: "space-between",
    "li": {
        padding: 0,
    },
    ".MuiButton-root, a": {
        lineHeight: "normal",
        fontSize: theme.sizes.fonts.h3,
        whiteSpace: "nowrap",
        borderRadius: `${theme.sizes.radius.large} ${theme.sizes.radius.large} 0 0`,
        paddingLeft: 0,
        paddingRight: 0,
        "&:first-of-type": {
        justifyContent: "flex-start",
            "> *": {
                paddingLeft: 0,
            }
        },
    },
    ".red-button-wrapper": {
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 0,
        "a": {
            marginTop: 0,
            marginBottom: 0,
            padding: `${theme.sizes.triplePadding} ${theme.sizes.doublePadding}`,
        }
    }
}));

/* MegaMenuItems */
export const StyledMenuItemWrapper = styled(ListItem)(() => ({
    display: 'flex',
    justifyContent: "center",
    width: "auto",
    "> *": {
        margin: 0,
    },
    ":before": {
        content: "none",
    },
    "&:first-of-type": {
        justifyContent: "flex-start",
        "> *": {
            paddingLeft: 0,
        }
    },
    "&:last-of-type": {
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginBottom: 0,
        paddingBottom: 0,
        "> *": {
            marginBottom: 0,
        }
    },
    "a:not(.MuiButton-root)": {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        width: 'fit-content',
        flex: "0 0 auto",
        color: theme.colors.mainGrey,
        '&:hover': {
        textDecoration: 'underline',
        },
    }
}));

export const PopoverWrapper = styled(Popover)(() => ({
    display: 'flex',
    ".MuiPopover-paper": {
        display: 'flex',
        flexFlow: 'row',
        padding: `${theme.sizes.quadricPadding} ${theme.sizes.quadricPadding} ${theme.sizes.octoMargin}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: theme.sizes.radius.large,
        borderBottomRightRadius: theme.sizes.radius.large,
        boxShadow: 'none',
        backgroundImage: `url("${imageUrl}images/Header/Menu/mega-menu-bg.svg")`,
        backgroundRepeat: "no-repeat", /* Ensure the image does not repeat */
        backgroundPosition: "left bottom", /* Position the image at the bottom-left */
        borderTop: `solid 1px ${theme.colors.mainLightGrey}`,
    }
}));

export const StyledMegaMenuItem = styled(Box)(() => ({
    position:"relative",
    display: 'flex',
    flexDirection: 'column',
    alignItems: "flex-start",
    width: "auto",
    maxWidth: "100%",
    backgroundColor: theme.colors.whiteSmoke,
    paddingInlineEnd: theme.sizes.cardsGap,
    "&:last-child": {
        [`${theme.mediaQueries.desktopM}`]: {
            paddingInlineStart: theme.sizes.cardsGap,
        },
        [`${theme.mediaQueries.desktop}`]: {
            paddingInlineStart: theme.sizes.cardsGap,
        },
        [`${theme.mediaQueries.desktop}`]: {
            paddingInlineStart: theme.sizes.cardsGap,
        },
        ".links-wrapper": {
            flexFlow: 'row wrap',
            justifyContent: "center",
            "a": {
                backgroundColor: theme.colors.white,
            },
            
        },
        [`${theme.mediaQueries.desktopM}`]: {  
            ".title": {
                "h3": {
                    border: "none",
                }
            },
            borderInlineStart: `solid 1px ${theme.colors.mainLightGrey}`,
            ".links-wrapper": {
                flexFlow: 'column wrap',
                justifyContent: "center",
                "a": {
                    backgroundColor: theme.colors.white,
                }
            },
        },
    },
    "a": {
        textDecoration: "none",
        '&:hover, &:focus': {
            textDecoration: "none",
        }
    },
    ".title": {
        "h3": {
            fontSize: theme.sizes.fonts.h4,
            marginBottom: theme.sizes.baseMargin,
            [`${theme.mediaQueries.desktopM}`]: {
                marginBottom: theme.sizes.quadricMargin,
                fontSize: theme.sizes.fonts.desktop.h4,
                width: "100%",
                borderBottom: `solid 1px ${theme.colors.mainLightGrey}`,
                
            }
        },
        [`${theme.mediaQueries.desktopM}`]: {
            width: "100%",  
        }
    },
    ".links-wrapper": {
        display: 'flex',
        maxHeight: "200px",
        flexFlow: 'column wrap',
        justifyContent: "flex-start",
        marginLeft: theme.sizes.doubleMargin,
        gap: theme.sizes.gap,
        "a": {
            flexGrow: 1,
            boxSizing: "border-box",
            lineHeight: "normal",
            fontSize: theme.sizes.fonts.megaMenu,
            padding: `${theme.sizes.basePadding} ${theme.sizes.doublePadding}`,
            marginInlineEnd: 0,
            "&:not(.MuiButton-root)": {
                padding: `${theme.sizes.basePadding} 0 ${theme.sizes.doublePadding} 0`,
                width: "auto",
                '&:hover, &:focus': {
                    textDecoration: "none",
                    textShadow: '.5px 0px 0px rgba(0, 0, 0, 1)',
                }
            }
        },
        [`${theme.mediaQueries.desktopM}`]: {
            marginLeft: "unset",
            gap: "unset",
            "a": {
                fontSize: theme.sizes.fonts.desktop.megaMenu,
                marginRight: theme.sizes.triplePadding,
            }
        }
    },
    [`${theme.mediaQueries.desktopM}`]: {
        backgroundColor: theme.colors.white,
        "&:last-child": {
            borderInlineStart: `solid 1px ${theme.colors.mainLightGrey}`,
            ".links-wrapper": {
                flexFlow: 'column wrap',
                justifyContent: "center",
                "a": {
                    backgroundColor: theme.colors.white,
                }
            },
        },
    },
}));
import React from 'react';
import { IconMenuProps } from './types';
import { IconMenuWrapper, StyledIconItem } from './styles';
import { Icons } from '../../reusable/IconsComponents';
import Image from 'next/image';
import { handleGTMEvent } from '@/common/GTMEventSender';
import { toPascalCase, capitalizeFirstLetter } from '@/lib/utils/stringMethods';

const IconMenu: React.FC<IconMenuProps> = ({ data, images,  GTMEventName }) => {
    const {item} = data;
    return (
      <IconMenuWrapper>
        { item && item.map((linkItem, index)=> {
            const { href, title } = linkItem;
            const classArr = linkItem.class;
            const ImportedIcon = Icons[capitalizeFirstLetter(toPascalCase(classArr[0]) + "Icon") as keyof typeof Icons] || null; // import icon dynamically
            let imageLink;
            if(images) imageLink = images[index] ? images[index] : '';
            if(href) {
                return (
                    <StyledIconItem className="nav-item" href={href} id={`${GTMEventName}-icon-menu-button-${title}`} key={'nav-icon-item-' + title ? title : '' + index} onClick={() => 
                        handleGTMEvent(GTMEventName ? GTMEventName : 'icon-menu-button', `${GTMEventName}-icon-menu-button-${title}`,{
                        item_url: href ? href : "null",
                        item_text: title,
                      })}>
                        { ImportedIcon && <ImportedIcon width={24} height={24} /> } 
                        { imageLink && 
                            <Image src={imageLink}
                                    width={50}
                                    height={50}
                                   alt={title}
                            />
                        } 
                        { title && !ImportedIcon && 
                                <>{title}</>
                        }  
                    </StyledIconItem>
                )
            }
        })}
      </IconMenuWrapper>
    );
  };
  
  export default IconMenu;
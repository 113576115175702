"use client";

import React, { Suspense, useEffect, useState } from "react";
import {
  CookieBannerContent,
  CookieBannerTexts,
  CookieBannerWrapper,
} from "./styles";
import BottomBanner from "../reusable/BottomBanner/BottomBanner";
import { CookieBannerProps } from "./types";
import Image from "next/image";
import { Typography } from "@mui/material";
import { ArrowLinkNoAnimation } from "../reusable/ArrowLink/ArrowLink";
import Cookies from "js-cookie";
import { handleGTMEvent } from "@/common/GTMEventSender";
import { sendBcodesEvent } from "@/common/Bcodes";
import { GoogleTagManager } from "@next/third-parties/google";

const gtmId = process.env.GTM_ID ? process.env.GTM_ID : "";

const CookieBanner: React.FC<CookieBannerProps> = ({
  cookieExpirationPeriod,
  cookieDomain,
  cookiesBanner,
}) => {
  const [showBanner, setShowBanner] = useState(true);
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);
  const cookieName = "banner_cookies";
  const cookieLengthName = "banner_cookies_length";
  const GTMEvent = "cookie_banner";
  const { imageUrl, links, title } = cookiesBanner;

  const setCookie = () => {
    const currCookies = Cookies.get("banner_cookies");
    const currConcent = Cookies.get("consent");
    if (!currConcent || currConcent === "true") setIsCookieAccepted(true);
    if (!currCookies && (!currConcent || currConcent === "true")) {
      Cookies.set(cookieName, "1", {
        cookieExpirationPeriod,
        path: cookieDomain,
        sameSite: "Lax",
      });
      Cookies.set(cookieLengthName, "169", {
        cookieExpirationPeriod,
        path: cookieDomain,
        sameSite: "Lax",
      });
    }
  };

  useEffect(() => {
    const currConcent = Cookies.get("consent");
    if (!currConcent || currConcent === "true") setIsCookieAccepted(true);
  }, []);

  useEffect(() => {
    const currCookies = Cookies.get("banner_cookies");
    const currConcent = Cookies.get("consent");
    setShowBanner(
      !currCookies && (!currConcent || currConcent === "true") ? true : false
    );
    sendBcodesEvent(currCookies || "", currConcent || "");
  }, [isCookieAccepted]);

  return (
    <CookieBannerWrapper>
      {isCookieAccepted && (
        <Suspense>
          <GoogleTagManager gtmId={gtmId} />
        </Suspense>
      )}
      {showBanner && (
        <BottomBanner additionalCloseFunc={setCookie} GTMEvent={GTMEvent}>
          <CookieBannerContent>
            {imageUrl && (
              <Image width={30} height={30} src={imageUrl} alt={title} />
            )}
            <CookieBannerTexts>
              {title && <Typography>{title}</Typography>}
              <div className="cookie-links-wrapper">
                {links &&
                  links.map((linkItem) => {
                    if (linkItem?.href)
                      return (
                        <ArrowLinkNoAnimation
                          id={`${GTMEvent}-read-more-${linkItem?.text}`}
                          key={`cookie-link-${linkItem?.text}`}
                          href={linkItem?.href}
                          text={linkItem?.text}
                          onClick={() =>
                            handleGTMEvent(
                              GTMEvent,
                              `${GTMEvent}-read-more-${linkItem?.text}`,
                              {
                                item_url: linkItem?.href ? linkItem?.href : "",
                                item_text: linkItem?.text,
                              }
                            )
                          }
                        />
                      );
                  })}
              </div>
            </CookieBannerTexts>
          </CookieBannerContent>
        </BottomBanner>
      )}
    </CookieBannerWrapper>
  );
};

export default CookieBanner;

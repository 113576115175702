import { MegaItem } from "@/components/Menus/MegaMenu/types";

// Function to build a hierarchical menu structure from a flat list of items
const buildMenuHierarchy = (items: MegaItem[]): MegaItem[] => {
    // Initialize the root of the menu hierarchy
    const root: MegaItem[] = [];
    // Iterate through each item in the input list
    items.forEach(item => {
        let currentLevel = root; // Start at the root level
        // Convert the hierarchy to numbers and handle undefined hierarchy gracefully
        const hierarchy = item.hierarchy?.map(Number) ?? []; 
        // Iterate through each level of the hierarchy
        hierarchy.forEach((level, index) => {
            // Ensure there's a place for the current level in the hierarchy
            if (!currentLevel[level]) {
                currentLevel[level] = {
                    title: '', // Placeholder title, will be replaced later
                    children: [] // Initialize children as an empty array
                };
            }
            // If this is the last level in the hierarchy, assign the item
            if (index === hierarchy.length - 1) {
                currentLevel[level] = { 
                    title: item.title, // Set the title from the item
                    ...(item.class ? { class: item.class } : {}), // Conditionally add class if it exists
                    ...(item.href ? { href: item.href } : {}), // Conditionally add href if it exists
                    children: [] // Initialize children as an empty array
                };
            }
            // Ensure that the current level has a children array
            if (!currentLevel[level].children) {
                currentLevel[level].children = [];
            }
            // Move to the next level down in the hierarchy
            currentLevel = currentLevel[level].children!;
        });
    });

    // Helper function to clean up the menu hierarchy by removing unnecessary properties
    const cleanUp = (items: MegaItem[]): MegaItem[] => {
        return items
            .filter(item => item.title) // Keep only items with a non-empty title
            .map(item => ({
                title: item.title, // Keep the title
                ...(item.class ? { class: item.class } : {}), // Keep class if it exists
                ...(item.href ? { href: item.href } : {}), // Keep href if it exists
                children: item.children ? cleanUp(item.children) : [] // Recursively clean up children
            }));
    };

    // Return the cleaned-up root hierarchy
    return cleanUp(root);
};

export default buildMenuHierarchy;
import { Box } from "@mui/material";
import Script from "next/script";
import { ButterflyWrapper } from "./styles";
import { FooterProps } from "@/components/Layout/types";
import { imageUrl } from "@/theme/theme";

const Butterfly = ({ butterflyText }: FooterProps) => {
  const imgUrl = `${imageUrl}images/butterfly-logo-200.png`; // TODO: get from BE
  return (
    <ButterflyWrapper>
      {/* Load the external Butterfly Plugin */}
      <Script
        src="/libraries/butterfly/butterfly-plugin.js"
        strategy="afterInteractive" // Load the script after the page is interactive
      />
      <Script
        src="/libraries/poalim-gtm/poalim_gtm.js"
        strategy="afterInteractive" // Load the script after the page is interactive
      />

      {/* Render the Butterfly button image */}
      <img
        onClick={() => {
          if (typeof window.bf_ReporterPlugin !== "undefined") {
            window.bf_ReporterPlugin.toggleButterflyReporter(
              "9cb70c03-22a3-494f-a376-2f53e1f79cb9",
              "il",
              "he"
            );
          } else {
            console.error("Butterfly Reporter Plugin is not loaded.");
          }
        }}
        src={imgUrl}
        alt="The Butterfly Button"
      />
      <Box
        onClick={() =>
          window.bf_ReporterPlugin.toggleButterflyReporter(
            "9cb70c03-22a3-494f-a376-2f53e1f79cb9",
            "il",
            "he"
          )
        }
        className="butterfly-text"
      >
        {butterflyText}
      </Box>
    </ButterflyWrapper>
  );
};

export default Butterfly;

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hapoalim-front-end/components/CookieBanner/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hapoalim-front-end/components/Layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hapoalim-front-end/components/Layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/hapoalim-front-end/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hapoalim-front-end/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/hapoalim-front-end/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/hapoalim-front-end/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../fonts/Poalim2023-Bold.ttf\",\"weight\":\"bold\"},{\"path\":\"../../fonts/Poalim2023-Regular.ttf\",\"weight\":\"normal\"},{\"path\":\"../../fonts/Poalim2023-Light.ttf\",\"weight\":\"100\"}],\"variable\":\"--fonts-next\"}],\"variableName\":\"fontsNext\"}");
;
import(/* webpackMode: "eager" */ "/var/www/hapoalim-front-end/theme/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/hapoalim-front-end/theme/ThemeProviders.tsx");

"use client";

import { Typography } from '@mui/material';
import ArrowLeft from '@/public/icons/General/chevron-left.svg';
import Link from 'next/link';
import React, { useCallback, useState } from 'react'
import { CollapseItem, CollapseTitle, CollapseWrapper } from './styles';
import { handleGTMEvent } from '@/common/GTMEventSender';

type GenericComponentProps<T> = {
  data: {
    item: T;
  };
  className?: string;
  GTMEventName?: string;
};

interface CollapseMenuInter<T> {
  href?: any;
  children?: T[];
  title?: string;
}

function CollapseMenu<T extends CollapseMenuInter<T>>({
  data,
  className,
  GTMEventName,
}: GenericComponentProps<T>) {
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const { item } = data;

  const handleSubMenuClick = useCallback((text: string) => {
    setOpenSubMenu((prev) => (prev === text ? null : text));
  }, []);
  if (item)
    return (
      <CollapseWrapper className={className ? className : ""}>
        <CollapseTitle
          onClick={() =>
            item.children && handleSubMenuClick(item.title ? item.title : "")
          }
        >
          <Typography className={openSubMenu === item.title ? "open" : ""}>
            {item.title}
          </Typography>
          {item.children && item.children.length > 0 && (
            <ArrowLeft className={openSubMenu === item.title ? "open" : ""} />
          )}
        </CollapseTitle>
        <CollapseItem
          in={openSubMenu === item.title}
          timeout="auto"
          unmountOnExit
        >
          {item?.children &&
            item?.children.map((child, index) => {
              return (
                <a
                  key={`collapse-link-${child.title}-${index}`}
                  href={child.href ? child.href : ""}
                  id={`${GTMEventName}-mobile-menu-item-${child.title}`}
                  onClick={() =>
                    handleGTMEvent(
                      GTMEventName
                        ? `${GTMEventName}-mobile`
                        : "mobile-menu-item",
                      `${GTMEventName}-mobile-menu-item-${child.title}`,
                      {
                        item_url: child.href ? child.href : "null",
                        item_text: child.title,
                      }
                    )
                  }
                >
                  {child.title}
                </a>
              );
            })}
        </CollapseItem>
      </CollapseWrapper>
    );
}

export default CollapseMenu;

'use client'
import { styled, Box } from "@mui/material";
import theme from "@/theme/theme";

export const ButterflyWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: 'pointer',
    img: {
        width: "25px",
        height: "auto",
        [`${theme.mediaQueries.desktopM}`]: {
            width: "50px",
            height: "auto",
        }
    },
    ".butterfly-text": {
        marginTop: theme.sizes.baseMargin,
        fontSize: "10px",
        [`${theme.mediaQueries.desktopM}`]: {
            fontSize: theme.sizes.fonts.body,
        }
    }
}))
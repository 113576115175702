"use client";

import React from "react";
import LinkMenu from "../../components/Menus/LinkMenu/LinkMenu";
import IconMenu from "../Menus/IconMenu/IconMenu";
import {
  StyledFooter,
  FooterWrapper,
  FooterIconsWrapper,
  LinkMenusWrapper,
  AppsWrapper,
  MobileLinkButtonsWrapper,
  ButterflySocialWrapper,
} from "./styles";
import { FooterProps } from "./types";
import { Desktop, Mobile } from "@/common/MediaQueries";
import CollapseMenu from "../Menus/CollapseMenu/CollapseMenu";
import buildMenuHierarchy from "@/lib/utils/menuParsers";
import { MegaItem } from "../Menus/MegaMenu/types";
import AppsButtonsComponent from "../AppsButtonsComponent/AppsButtonsComponent";
import { Button } from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import Butterfly from "../Butterfly/Butterfly";
import { handleGTMEvent } from "@/common/GTMEventSender";
import { imageUrl } from "@/theme/theme";

const Footer: React.FC<FooterProps> = ({
  iconsMenu,
  linkMenu,
  socialMenu,
  appsData,
  footerClientMenu,
  butterflyText,
}) => {
  const menuIcons = [
    `${imageUrl}images/Footer/NavIcons/Icon-0.png`, // only the ones that are images, the svg ones are mapped separately
    `${imageUrl}images/Footer/NavIcons/Icon-1.png`,
    `${imageUrl}images/Footer/NavIcons/Icon-2.png`,
    `${imageUrl}images/Footer/NavIcons/Icon-3.png`,
    `${imageUrl}images/Footer/NavIcons/Icon-4.png`,
    `${imageUrl}images/Footer/NavIcons/Icon-5.png`,
  ];
  let parsedLinkMenuData;
  if (linkMenu && linkMenu[0] && linkMenu[0][0])
    parsedLinkMenuData = buildMenuHierarchy(linkMenu[0][0].item);
  const GTMEvent = "footer";

  return (
    <StyledFooter>
      <FooterWrapper>
        <LinkMenusWrapper>
          {parsedLinkMenuData &&
            parsedLinkMenuData.map((item, index) => {
              const parsedItem = {
                anchor: "linkMenu",
                item: item,
              };
              return (
                <React.Fragment key={`${item.title}-${index}`}>
                  <Desktop>
                    <LinkMenu
                      GTMEventName={GTMEvent}
                      className="vertical"
                      key={"link-menu-" + index}
                      data={item}
                    />
                  </Desktop>
                  <Mobile>
                    <CollapseMenu<MegaItem>
                      key={"link-menu-" + index}
                      data={parsedItem}
                      className="footer-collapse"
                      GTMEventName={GTMEvent}
                    />
                  </Mobile>
                </React.Fragment>
              );
            })}
          <Mobile>
            <MobileLinkButtonsWrapper>
              {footerClientMenu?.item &&
                footerClientMenu?.item.map((item) => {
                  const { title, href } = item;
                  return (
                    <Button
                      key={`mobile-link-button-${title}`}
                      id={`${GTMEvent}-mobile-link-button-${title}`}
                      href={href}
                      variant="elevated"
                      onClick={() =>
                        handleGTMEvent(
                          GTMEvent
                            ? `${GTMEvent}-mobile`
                            : "mobile-link-button",
                          `${GTMEvent}-mobile-link-button-${title}`,
                          {
                            item_url: href ? href : "null",
                            item_text: title,
                          }
                        )
                      }
                    >
                      {title}
                      <ArrowLeft />
                    </Button>
                  );
                })}
            </MobileLinkButtonsWrapper>
          </Mobile>
          <FooterIconsWrapper>
            {iconsMenu &&
              iconsMenu[0] &&
              iconsMenu[0].map((item, index) => {
                return (
                  <IconMenu
                    GTMEventName={GTMEvent}
                    key={"icon-menu-" + index}
                    data={item}
                    images={menuIcons}
                  />
                );
              })}
          </FooterIconsWrapper>
        </LinkMenusWrapper>
        <AppsWrapper>
          {appsData && (
            <AppsButtonsComponent
              data={appsData?.data}
              logo={appsData?.logo}
              appButtons={appsData?.appButtons}
              disableAnimation={true}
              GTMEventName={GTMEvent}
            />
          )}
          <ButterflySocialWrapper>
            <Butterfly
              butterflyText={butterflyText}
              iconsMenu={[]}
              linkMenu={[]}
              socialMenu={[]}
              footerClientMenu={{
                item: [],
              }}
            />
            {socialMenu &&
              socialMenu[0] &&
              socialMenu[0].map((item, index) => {
                return (
                  <IconMenu
                    GTMEventName={GTMEvent}
                    key={"icon-menu-" + index}
                    data={item}
                  />
                );
              })}
          </ButterflySocialWrapper>
        </AppsWrapper>
      </FooterWrapper>
    </StyledFooter>
  );
};

export default Footer;

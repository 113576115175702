"use client";

import React, { useCallback, useState } from "react";
import { MegaItem } from "./types";
import { ArrowDownLinkButton } from "@/components/reusable/ArrowLink/ArrowLink";
import { StyledMenuItemWrapper, PopoverWrapper } from "./styles";
import MegaMenuItem from "./MegaMenuItem";
import { Button } from "@mui/material";
import { handleGTMEvent } from "@/common/GTMEventSender";

interface MegaMenuItemProps {
  item: MegaItem;
  index?: number | undefined;
  total?: number | undefined;
  GTMEventName?: string;
}

export const MegaMenuItems: React.FC<MegaMenuItemProps> = ({
  item,
  index,
  total,
  GTMEventName,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorPlace, setAnchorPlace] = useState(0);
  const [anchorHeight, setAnchorHeight] = useState(0);
  const [afterHalf, setAfterHalf] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "mega-menu-popover" : undefined;

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if ((index || index === 0) && total) {
      setAfterHalf(index > total * 0.5);
      setAnchorHeight(event.currentTarget.offsetHeight);
      setAnchorPlace(event.currentTarget.offsetWidth);
      setAnchorEl(event.currentTarget);
    }
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <StyledMenuItemWrapper
      className={
        item.class && item.class.includes("button") ? "red-button-wrapper" : ""
      }
    >
      {item.children && item.children.length > 0 ? (
        <>
          <ArrowDownLinkButton
            variant="ghost"
            aria-describedby={id}
            onClick={handleClick}
            className={open ? "open" : ""}
            text={item.title}
            tabIndex={index}
          />
          <PopoverWrapper
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: anchorHeight + 17,
              horizontal: afterHalf ? 0 : anchorPlace,
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: afterHalf ? "left" : "right",
            }}
          >
            {item.children.map((child, index) => {
              const currIndex = index;
              const itemsAmount = item.children
                ? item.children.length - 1
                : undefined;

              return (
                <MegaMenuItem
                  GTMEventName={`${GTMEventName}`}
                  key={`${child.title}-${index}`}
                  item={child}
                  outerIndex={currIndex}
                  outerChildren={itemsAmount}
                  handleClose={handleClose}
                />
              );
            })}
          </PopoverWrapper>
        </>
      ) : (
        <>
          {item.class && item.class.includes("button") ? (
            <Button
              id={`${GTMEventName}-mega-link-${item.title}`}
              href={item.href ? item.href : ""}
              onClick={() =>
                handleGTMEvent(
                  GTMEventName ? `${GTMEventName}-mobile` : "mobile-mega-link",
                  `${GTMEventName}-mega-link-${item.title}`,
                  {
                    item_url: item.href ? item.href : "null",
                    category: "mega-menu",
                    item_text: item.title,
                  }
                )
              }
            >
              {item.title}
            </Button>
          ) : (
            <a href={item.href ? item.href : ""}>{item.title}</a>
          )}
        </>
      )}
    </StyledMenuItemWrapper>
  );
};

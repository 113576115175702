"use client";

import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import theme from "./theme";

const ThemeProviders = ({ children }: { children: React.ReactNode }) => (
  <AppRouterCacheProvider
    options={{
      key: "muirtl",
      stylisPlugins: [rtlPlugin],
    }}
  >
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </AppRouterCacheProvider>
);

export default ThemeProviders;

interface BCodesWindow extends Window {
  bnhpApp?: {
    sendCode: (code: number) => void;
  }
}

interface BnhpApp {
  sendCode: (code: string) => void;
}

declare global {
  var bnhpApp: BnhpApp | undefined;
}


declare const window: BCodesWindow;

const scriptSrc = process.env.BCODE_URL ? process.env.BCODE_URL : 'https://bcodes.bankhapoalim.co.il/api.js';

let isSendingInitiated = false;
const initBcodes = () => {
    const script = document.createElement('script');
    script.src = scriptSrc;
    document.head.appendChild(script);
    script.onload = () => {
      if (typeof bnhpApp !== 'undefined') {
        sendBcodesEvent("1", "true");
      }
    };
};

export const sendBcodesEvent = (bannerCookies: string, bannerConsent: string | null) => {
    if (!bannerConsent || bannerConsent === 'true') {
      if (typeof bnhpApp === 'undefined') {
          initBcodes();
      }
      try {
          if (typeof bnhpApp !== 'undefined' && !isSendingInitiated) {
            bnhpApp.sendCode("70078620301001");
            isSendingInitiated = true;
          }
        } catch (error) {
          console.error('Failed to send Bcodes event:', error);
        }
    }
};

import { styled } from "@mui/material/styles";
import { LinkProps } from "next/link";
import { SxProps, Theme } from "@mui/system";
import { Box, Typography } from "@mui/material";
import theme from "@/theme/theme";

export const LinkMenuWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
  width: "100%",
  [`${theme.mediaQueries.desktopM}`]: {
    justifyContent: "center",
    flexFlow: "row nowrap",
    "&.vertical": {
      flexDirection: "column",
      justifyContent: "start",
      gap: theme.sizes.gap,
      width: "125px",
      marginInlineEnd: theme.sizes.octoMargin,
      a: {
        margin: 0,
      },
    },
  },
  ".title": {
    fontSize: theme.sizes.fonts.h4,
    fontWeight: "bold",
    cursor: "default",
    "&:hover": {
      borderBottom: "1px solid transparent",
    },
  },
});

export const svgSharedStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: theme.sizes.icon.xSmall,
  height: theme.sizes.icon.xSmall,
  marginInlineStart: theme.sizes.baseMargin,
};

export const StyledLinkItem = styled("a")<LinkProps>({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  margin: `0 4px`,
  textDecoration: "none",
  flex: "0 0 auto",
  transition: "font-weight 0.3s ease",
  order: 3,
  zIndex: 0,
  padding: `${theme.sizes.basePadding} 0`,
  marginTop: theme.sizes.quadricMargin,
  h6: {
    fontSize: theme.sizes.fonts.megaMenu,
  },
  [`${theme.mediaQueries.desktopM}`]: {
    h6: {
      fontSize: theme.sizes.fonts.desktop.megaMenu,
    },
    margin: `0 ${theme.sizes.doubleMargin}`,
    padding: "unset",
    order: "unset",
  },
  "&.logo-wrapper": {
    position: "absolute",
    top: "-52px",
    right: "30%",
    left: "30%",
    width: "auto",
    flex: "1 0 auto",
    justifyContent: "center",
    order: 1,
    zIndex: "10",
    padding: `${theme.sizes.doublePadding} 0`,
    [`${theme.mediaQueries.desktopM}`]: {
      position: "relative",
      padding: "unset",
      order: "unset",
      top: "unset",
      right: "unset",
      left: "unset",
      flex: "1 1 auto",
      margin: `0 ${theme.sizes.doubleMargin}`,
    },
    ".logo": {
      width: "156px",
      height: "17px",
      [`${theme.mediaQueries.tablet}`]: {
        margin: "0",
        height: "26px",
      },
      [`${theme.mediaQueries.desktop}`]: {
        height: "26px",
        margin: "0 auto",
      },
    },
  },
  "&.icon-wrapper": {
    top: "-40px",
    right: "10%",
    order: 2,
    zIndex: "10",
    position: "absolute",
    [`${theme.mediaQueries.desktopM}`]: {
      position: "relative",
      order: "unset",
      top: "unset",
      right: "unset",
      left: "unset",
    },
  },

  "svg:not(.logo)": {
    fill: "none",
    stroke: theme.colors.mainRed,
    ...svgSharedStyle,
  },

  "&:first-of-type": {
    marginInlineStart: 0,
  },
  "&:last-of-type": {
    marginInlineEnd: 0,
  },
});

export const StyledLinkReplacement = styled(Typography)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: `0 4px`,
  cursor: "pointer",
  padding: `${theme.sizes.basePadding} 0`,
  fontSize: theme.sizes.fonts.megaMenu,
  svg: {
    stroke: theme.colors.mainRed,
    fontSize: "1em",
    ...svgSharedStyle,
  },
  "&.icon-wrapper": {
    top: "-10px",
    position: "absolute",
    order: 2,
    zIndex: "10",
    right: 0,
    [`${theme.mediaQueries.desktopM}`]: {
      position: "relative",
      order: "unset",
      top: "unset",
      right: "unset",
      left: "unset",
    },
  },
  [`${theme.mediaQueries.desktopM}`]: {
    fontSize: theme.sizes.fonts.desktop.megaMenu,
    margin: `0 ${theme.sizes.doubleMargin}`,
  },
});

"use client";

import { AppBar, Box, Container, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "@/theme/theme";

// Header
export const StyledAppBar = styled(AppBar)(() => ({
  boxShadow: "none",
  zIndex: 1,
  ".MuiContainer-root": {
    paddingBottom: 0,
  },
}));

export const HeaderToolbar = styled(Toolbar)(() => ({
  display: "flex",
  flexFlow: "row",
  justifyContent: "space-between",
  backgroundColor: theme.colors.white,
  boxShadow: theme.colors.dropShadow.main,
  overflow: "hidden",
  ".MuiContainer-root": {
    paddingTop: theme.sizes.containerPaddingHalf,
  },
  [`${theme.mediaQueries.desktopM}`]: {
    backgroundColor: "transparent",
    flexDirection: "column",
    paddingBottom: 0,
    ".MuiContainer-root": {
      padding: `${theme.sizes.desktop.containerPaddingHalf} 0 0 0`,
    },
  },
}));

export const StyledFooter = styled("footer")(() => ({
  boxShadow: theme.colors.dropShadow.main,
  width: "100%",
}));

// Footer
export const FooterWrapper = styled(Container)(() => ({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: theme.colors.white,
  marginTop: 0,
  width: "100%",
  [`${theme.mediaQueries.tablet}`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`${theme.mediaQueries.desktopM}`]: {
    marginTop: theme.sizes.octoMargin,
    padding: theme.sizes.basePadding,
    flexFlow: "row wrap",
    alignItems: "flex-start",
  },
}));

export const LinkMenusWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: theme.colors.white,
  [`${theme.mediaQueries.desktopM}`]: {
    flexFlow: "row wrap",
    justifyContent: "space-between",
  },
  ".footer-collapse": {
    fontSize: theme.sizes.fonts.menu,
    padding: 0,
    p: {
      fontSize: theme.sizes.fonts.menu,
    },
    ".MuiCollapse-wrapperInner": {
      background: theme.colors.whiteSmoke,
    },
  },
}));

export const MobileLinkButtonsWrapper = styled(Container)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.sizes.gap,
  paddingTop: theme.sizes.triplePadding,
  paddingBottom: theme.sizes.triplePadding,
  ".MuiButton-root": {
    color: theme.colors.mainGrey,
    fontSize: theme.sizes.fonts.body,
    padding: `${theme.sizes.halfBasePadding} ${theme.sizes.doublePadding}`,
    svg: {
      marginInlineStart: theme.sizes.baseMargin,
      stroke: "none",
      fill: theme.colors.mainRed,
      fontSize: "1.2em",
    },
  },
}));

export const AppsWrapper = styled(Box)(() => ({
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  width: "100%",
  [`${theme.mediaQueries.desktopM}`]: {
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  ".apps-buttons": {
    marginBottom: theme.sizes.baseMargin,
    alignItems: "center",
    order: 2,
    [`${theme.mediaQueries.desktopM}`]: {
      alignItems: "start",
      order: "unset",
      "> *": {
        marginBottom: theme.sizes.baseMargin,
      },
    },
  },
  ".content": {
    p: {
      fontSize: "14px",
    },
  },
}));

export const ButterflySocialWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  padding: `0 ${theme.sizes.containerPadding}`,
  width: "100%",
  order: 1,
  alignItems: "center",
  [`${theme.mediaQueries.desktopM}`]: {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: 0,
    width: "unset",
    order: "unset",
  },
  "> *": {
    marginBottom: theme.sizes.tripleMargin,
  },
}));

export const FooterIconsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: "0 0 auto",
  margin: 0,
  "> *": {
    marginBottom: theme.sizes.quadricMargin,
    width: 200,
  },
  [`${theme.mediaQueries.desktopM}`]: {
    margin: `${theme.sizes.quadricMargin} 0`,
    height: "100%",
  },
}));

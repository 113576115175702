"use client";

import { Typography, Button } from "@mui/material";
import React from "react";
import { StyledMegaMenuItem } from "./styles";
import { MegaItem } from "./types";
import { handleGTMEvent } from "@/common/GTMEventSender";

interface ItemMegaProps {
  item: MegaItem;
  handleClose?: () => void | undefined;
  outerChildren: number | undefined;
  outerIndex: number;
  GTMEventName?: string;
}

const MegaMenuItem: React.FC<ItemMegaProps> = ({
  item,
  outerIndex,
  outerChildren,
  handleClose,
  GTMEventName,
}) => {
  return (
    <StyledMegaMenuItem
      key={`mega-item-${item.title}-${outerIndex}`}
      onClick={handleClose}
    >
      <a
        id={`${GTMEventName}-${item.title}-${outerIndex}`}
        className="title"
        href={item.href ? item.href : ""}
        onClick={() =>
          handleGTMEvent(
            GTMEventName ? `${GTMEventName}` : "mega-link",
            `${GTMEventName}-${item.title}-${outerIndex}`,
            {
              item_url: item.href ? item.href : "null",
              item_category: item.title,
              item_text: item.title,
            }
          )
        }
      >
        <Typography variant="h3" width={"100%"}>
          {item.title}
        </Typography>
      </a>
      <div className="links-wrapper">
        {item.children ? (
          item.children.map((grandchild, innerIndex) => {
            if (outerIndex === outerChildren)
              return (
                <Button
                  variant="elevated"
                  key={`${grandchild.title}-${innerIndex}`}
                  className="small"
                  id={`${GTMEventName}-${grandchild.title}-${outerIndex}-${innerIndex}`}
                  href={grandchild.href ? grandchild.href : ""}
                  onClick={() => {
                    handleGTMEvent(
                      GTMEventName ? `${GTMEventName}` : "mega-link",
                      `${GTMEventName}-${grandchild.title}-${outerIndex}-${innerIndex}`,
                      {
                        item_url: grandchild.href ? grandchild.href : "null",
                        item_category: item.title,
                        item_text: grandchild.title,
                      }
                    );
                  }}
                >
                  {grandchild.title}
                </Button>
              );
            else
              return (
                <a
                  key={`${grandchild.title}-${innerIndex}`}
                  id={`${GTMEventName}-${item.title}-${outerIndex}`}
                  href={grandchild.href ? grandchild.href : ""}
                  onClick={() =>
                    handleGTMEvent(
                      GTMEventName ? `${GTMEventName}` : "mega-link",
                      `${GTMEventName}-${item.title}-${outerIndex}`,
                      {
                        item_url: item.href ? item.href : "null",
                        item_category: item.title,
                        item_text: item.title,
                      }
                    )
                  }
                >
                  {grandchild.title}
                </a>
              );
          })
        ) : (
          <a
            key={`mega-link-${item.title}-${outerIndex}`}
            id={`${GTMEventName}-${item.title}-${outerIndex}`}
            href={item.href ? item.href : ""}
            onClick={() =>
              handleGTMEvent(
                GTMEventName ? `${GTMEventName}` : "mega-link",
                `${GTMEventName}-${item.title}-${outerIndex}`,
                {
                  item_url: item.href ? item.href : "null",
                  item_category: item.title,
                  item_text: item.title,
                }
              )
            }
          >
            {item.title}
          </a>
        )}
      </div>
    </StyledMegaMenuItem>
  );
};

export default React.memo(MegaMenuItem);

"use client";

import { styled } from "@mui/material/styles";
import { LinkProps } from "next/link";
import { SxProps, Theme } from "@mui/system";
import { Box, BoxProps } from "@mui/material";
import theme from "@/theme/theme";

export const IconMenuWrapper = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.sizes.gap,
}));

export const svgSharedStyle: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "auto",
  height: "24px",
  strokeWidth: 0,
};

export const StyledIconItem = styled("a")<LinkProps>(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  fontSize: theme.sizes.fonts.small,
  "svg:not(.logo)": {
    stroke: theme.colors.mainGrey,
    ...svgSharedStyle,
  },
}));

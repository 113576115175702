'use client'

import React from 'react';
import LinkMenu from '../Menus/LinkMenu/LinkMenu';
import { HeaderToolbar, StyledAppBar } from './styles';
import MegaMenu from '../Menus/MegaMenu/MegaMenu';
import { HeaderProps } from './types';
import { Desktop, Mobile } from '@/common/MediaQueries';
import MobileAllMenu from '../Menus/MobileAllMenu/MobileAllMenu';
import { Container } from '@mui/material';

const Header:React.FC<HeaderProps> = ({data, megaMenuData, mobileAllData}) => {
  const GTMEvent = "header";
  return (
    <StyledAppBar position="static" color={"transparent"}>
        { data &&
        <HeaderToolbar>
          <Container>
            <LinkMenu GTMEventName={GTMEvent} data={data} />
            <Mobile>
              <MobileAllMenu GTMEventName={GTMEvent} data={ mobileAllData ? mobileAllData: []}/>
            </Mobile>
            <Desktop>
              <MegaMenu GTMEventName={GTMEvent} data={megaMenuData} />
            </Desktop>
          </Container>
          </HeaderToolbar>
        }
    </StyledAppBar>
  );
};

export default Header;
'use client'

import { Box, Drawer } from '@mui/material';
import { styled } from "@mui/material/styles";
import theme from "@/theme/theme";

/* MegaMenu Mobile */
export const MobileAllMenuWrapper = styled(Box)(() => ({
    ".mobile-menu-button": {
        position: "absolute",
        top: "10px",
        zIndex: 100
    }
}));

export const StyledDrawer = styled(Drawer)(()=>({
    position: "relative",
    color: theme.colors.mainGrey,
    ".MuiPaper-root": {
        marginTop: "88px",
        width: "100%",
        padding: `${theme.sizes.basePadding}`,
        paddingBottom: "88px",
        boxShadow: "none"
    },
    "a:not(.MuiButton-root)": {
        textDecoration: "none",
        color: theme.colors.mainGrey,
        padding:`${theme.sizes.basePadding} ${theme.sizes.doublePadding}`,
    },
    ".MuiButton-root": {
        marginLeft: "auto",
        marginRight: "auto"
    },
    "ul": {
        "li": {
            "&:before": {
                content: "none"
            },
            "svg": {
                cursor: "pointer",
                marginStart: "auto",
            }
        }
    }
}))
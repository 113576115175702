import React from 'react';
import buildMenuHierarchy from '@/lib/utils/menuParsers';
import mockMegaMenu from './mockMegaMenu.json';
import { MegaMenuProps } from './types';
import { Box } from '@mui/material';
import { MegaMenuItems } from './MegaMenuItems';
import { MegaMenuDesktop } from './styles';

const MegaMenu:React.FC<MegaMenuProps> = ({ data, GTMEventName }) => {
    if(!data) data = mockMegaMenu;
    const megaData = buildMenuHierarchy(data);

    return (
        <Box position="static">
            <MegaMenuDesktop>
              { megaData.map((item, index) => (
                  <MegaMenuItems GTMEventName={`${GTMEventName}`} key={`mega-menu-${index}`} item={item} index={index} total={megaData.length}/>
              ))}
            </MegaMenuDesktop>
        </Box>
    );
}

export default MegaMenu;
